exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-components-blog-article-index-js": () => import("./../../../src/components/blog/article/index.js" /* webpackChunkName: "component---src-components-blog-article-index-js" */),
  "component---src-components-common-beauty-treatments-bydgoszcz-page-kosmetologia-estetyczna-js": () => import("./../../../src/components/common/beautyTreatments/bydgoszczPageKosmetologiaEstetyczna.js" /* webpackChunkName: "component---src-components-common-beauty-treatments-bydgoszcz-page-kosmetologia-estetyczna-js" */),
  "component---src-components-common-beauty-treatments-bydgoszcz-page-laseroterapia-js": () => import("./../../../src/components/common/beautyTreatments/bydgoszczPageLaseroterapia.js" /* webpackChunkName: "component---src-components-common-beauty-treatments-bydgoszcz-page-laseroterapia-js" */),
  "component---src-components-common-beauty-treatments-bydgoszcz-page-modelowanie-sylwetki-js": () => import("./../../../src/components/common/beautyTreatments/bydgoszczPageModelowanieSylwetki.js" /* webpackChunkName: "component---src-components-common-beauty-treatments-bydgoszcz-page-modelowanie-sylwetki-js" */),
  "component---src-components-common-beauty-treatments-lodz-page-kosmetologia-estetyczna-js": () => import("./../../../src/components/common/beautyTreatments/lodzPageKosmetologiaEstetyczna.js" /* webpackChunkName: "component---src-components-common-beauty-treatments-lodz-page-kosmetologia-estetyczna-js" */),
  "component---src-components-common-beauty-treatments-lodz-page-laseroterapia-js": () => import("./../../../src/components/common/beautyTreatments/lodzPageLaseroterapia.js" /* webpackChunkName: "component---src-components-common-beauty-treatments-lodz-page-laseroterapia-js" */),
  "component---src-components-common-beauty-treatments-lodz-page-modelowanie-sylwetki-js": () => import("./../../../src/components/common/beautyTreatments/lodzPageModelowanieSylwetki.js" /* webpackChunkName: "component---src-components-common-beauty-treatments-lodz-page-modelowanie-sylwetki-js" */),
  "component---src-components-common-beauty-treatments-lodz-page-strefa-fitness-js": () => import("./../../../src/components/common/beautyTreatments/lodzPageStrefaFitness.js" /* webpackChunkName: "component---src-components-common-beauty-treatments-lodz-page-strefa-fitness-js" */),
  "component---src-components-common-beauty-treatments-torun-page-kosmetologia-estetyczna-js": () => import("./../../../src/components/common/beautyTreatments/torunPageKosmetologiaEstetyczna.js" /* webpackChunkName: "component---src-components-common-beauty-treatments-torun-page-kosmetologia-estetyczna-js" */),
  "component---src-components-common-beauty-treatments-torun-page-laseroterapia-js": () => import("./../../../src/components/common/beautyTreatments/torunPageLaseroterapia.js" /* webpackChunkName: "component---src-components-common-beauty-treatments-torun-page-laseroterapia-js" */),
  "component---src-components-common-beauty-treatments-torun-page-modelowanie-sylwetki-js": () => import("./../../../src/components/common/beautyTreatments/torunPageModelowanieSylwetki.js" /* webpackChunkName: "component---src-components-common-beauty-treatments-torun-page-modelowanie-sylwetki-js" */),
  "component---src-components-common-beauty-treatments-torun-page-stylizacja-paznokci-js": () => import("./../../../src/components/common/beautyTreatments/torunPageStylizacjaPaznokci.js" /* webpackChunkName: "component---src-components-common-beauty-treatments-torun-page-stylizacja-paznokci-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bydgoszcz-blog-js": () => import("./../../../src/pages/bydgoszcz-blog.js" /* webpackChunkName: "component---src-pages-bydgoszcz-blog-js" */),
  "component---src-pages-bydgoszcz-cennik-js": () => import("./../../../src/pages/bydgoszcz-cennik.js" /* webpackChunkName: "component---src-pages-bydgoszcz-cennik-js" */),
  "component---src-pages-bydgoszcz-cookies-js": () => import("./../../../src/pages/bydgoszcz-cookies.js" /* webpackChunkName: "component---src-pages-bydgoszcz-cookies-js" */),
  "component---src-pages-bydgoszcz-galeria-js": () => import("./../../../src/pages/bydgoszcz-galeria.js" /* webpackChunkName: "component---src-pages-bydgoszcz-galeria-js" */),
  "component---src-pages-bydgoszcz-index-js": () => import("./../../../src/pages/bydgoszcz/index.js" /* webpackChunkName: "component---src-pages-bydgoszcz-index-js" */),
  "component---src-pages-bydgoszcz-kontakt-js": () => import("./../../../src/pages/bydgoszcz-kontakt.js" /* webpackChunkName: "component---src-pages-bydgoszcz-kontakt-js" */),
  "component---src-pages-bydgoszcz-o-nas-js": () => import("./../../../src/pages/bydgoszcz-o-nas.js" /* webpackChunkName: "component---src-pages-bydgoszcz-o-nas-js" */),
  "component---src-pages-bydgoszcz-oferty-specjalne-js": () => import("./../../../src/pages/bydgoszcz-oferty-specjalne.js" /* webpackChunkName: "component---src-pages-bydgoszcz-oferty-specjalne-js" */),
  "component---src-pages-bydgoszcz-polityka-prywatnosci-js": () => import("./../../../src/pages/bydgoszcz-polityka-prywatnosci.js" /* webpackChunkName: "component---src-pages-bydgoszcz-polityka-prywatnosci-js" */),
  "component---src-pages-bydgoszcz-promocja-js": () => import("./../../../src/pages/bydgoszcz-promocja.js" /* webpackChunkName: "component---src-pages-bydgoszcz-promocja-js" */),
  "component---src-pages-bydgoszcz-zabiegi-bydgoszcz-kosmetologia-estetyczna-js": () => import("./../../../src/pages/bydgoszcz-zabiegi/bydgoszcz-kosmetologia-estetyczna.js" /* webpackChunkName: "component---src-pages-bydgoszcz-zabiegi-bydgoszcz-kosmetologia-estetyczna-js" */),
  "component---src-pages-bydgoszcz-zabiegi-bydgoszcz-laseroterapia-js": () => import("./../../../src/pages/bydgoszcz-zabiegi/bydgoszcz-laseroterapia.js" /* webpackChunkName: "component---src-pages-bydgoszcz-zabiegi-bydgoszcz-laseroterapia-js" */),
  "component---src-pages-bydgoszcz-zabiegi-bydgoszcz-modelowanie-sylwetki-js": () => import("./../../../src/pages/bydgoszcz-zabiegi/bydgoszcz-modelowanie-sylwetki.js" /* webpackChunkName: "component---src-pages-bydgoszcz-zabiegi-bydgoszcz-modelowanie-sylwetki-js" */),
  "component---src-pages-bydgoszcz-zabiegi-index-js": () => import("./../../../src/pages/bydgoszcz-zabiegi/index.js" /* webpackChunkName: "component---src-pages-bydgoszcz-zabiegi-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lodz-blog-js": () => import("./../../../src/pages/lodz-blog.js" /* webpackChunkName: "component---src-pages-lodz-blog-js" */),
  "component---src-pages-lodz-cennik-js": () => import("./../../../src/pages/lodz-cennik.js" /* webpackChunkName: "component---src-pages-lodz-cennik-js" */),
  "component---src-pages-lodz-cookies-js": () => import("./../../../src/pages/lodz-cookies.js" /* webpackChunkName: "component---src-pages-lodz-cookies-js" */),
  "component---src-pages-lodz-galeria-js": () => import("./../../../src/pages/lodz-galeria.js" /* webpackChunkName: "component---src-pages-lodz-galeria-js" */),
  "component---src-pages-lodz-index-js": () => import("./../../../src/pages/lodz/index.js" /* webpackChunkName: "component---src-pages-lodz-index-js" */),
  "component---src-pages-lodz-kontakt-js": () => import("./../../../src/pages/lodz-kontakt.js" /* webpackChunkName: "component---src-pages-lodz-kontakt-js" */),
  "component---src-pages-lodz-na-raty-js": () => import("./../../../src/pages/lodz-na-raty.js" /* webpackChunkName: "component---src-pages-lodz-na-raty-js" */),
  "component---src-pages-lodz-o-nas-js": () => import("./../../../src/pages/lodz-o-nas.js" /* webpackChunkName: "component---src-pages-lodz-o-nas-js" */),
  "component---src-pages-lodz-oferty-specjalne-js": () => import("./../../../src/pages/lodz-oferty-specjalne.js" /* webpackChunkName: "component---src-pages-lodz-oferty-specjalne-js" */),
  "component---src-pages-lodz-polityka-prywatnosci-js": () => import("./../../../src/pages/lodz-polityka-prywatnosci.js" /* webpackChunkName: "component---src-pages-lodz-polityka-prywatnosci-js" */),
  "component---src-pages-lodz-promocja-js": () => import("./../../../src/pages/lodz-promocja.js" /* webpackChunkName: "component---src-pages-lodz-promocja-js" */),
  "component---src-pages-lodz-zabiegi-index-js": () => import("./../../../src/pages/lodz-zabiegi/index.js" /* webpackChunkName: "component---src-pages-lodz-zabiegi-index-js" */),
  "component---src-pages-lodz-zabiegi-lodz-kosmetologia-estetyczna-js": () => import("./../../../src/pages/lodz-zabiegi/lodz-kosmetologia-estetyczna.js" /* webpackChunkName: "component---src-pages-lodz-zabiegi-lodz-kosmetologia-estetyczna-js" */),
  "component---src-pages-lodz-zabiegi-lodz-laseroterapia-js": () => import("./../../../src/pages/lodz-zabiegi/lodz-laseroterapia.js" /* webpackChunkName: "component---src-pages-lodz-zabiegi-lodz-laseroterapia-js" */),
  "component---src-pages-lodz-zabiegi-lodz-modelowanie-sylwetki-js": () => import("./../../../src/pages/lodz-zabiegi/lodz-modelowanie-sylwetki.js" /* webpackChunkName: "component---src-pages-lodz-zabiegi-lodz-modelowanie-sylwetki-js" */),
  "component---src-pages-lodz-zabiegi-lodz-strefa-fitness-js": () => import("./../../../src/pages/lodz-zabiegi/lodz-strefa-fitness.js" /* webpackChunkName: "component---src-pages-lodz-zabiegi-lodz-strefa-fitness-js" */),
  "component---src-pages-torun-blog-js": () => import("./../../../src/pages/torun-blog.js" /* webpackChunkName: "component---src-pages-torun-blog-js" */),
  "component---src-pages-torun-cennik-js": () => import("./../../../src/pages/torun-cennik.js" /* webpackChunkName: "component---src-pages-torun-cennik-js" */),
  "component---src-pages-torun-cookies-js": () => import("./../../../src/pages/torun-cookies.js" /* webpackChunkName: "component---src-pages-torun-cookies-js" */),
  "component---src-pages-torun-galeria-js": () => import("./../../../src/pages/torun-galeria.js" /* webpackChunkName: "component---src-pages-torun-galeria-js" */),
  "component---src-pages-torun-index-js": () => import("./../../../src/pages/torun/index.js" /* webpackChunkName: "component---src-pages-torun-index-js" */),
  "component---src-pages-torun-kontakt-js": () => import("./../../../src/pages/torun-kontakt.js" /* webpackChunkName: "component---src-pages-torun-kontakt-js" */),
  "component---src-pages-torun-o-nas-js": () => import("./../../../src/pages/torun-o-nas.js" /* webpackChunkName: "component---src-pages-torun-o-nas-js" */),
  "component---src-pages-torun-oferty-specjalne-js": () => import("./../../../src/pages/torun-oferty-specjalne.js" /* webpackChunkName: "component---src-pages-torun-oferty-specjalne-js" */),
  "component---src-pages-torun-polityka-prywatnosci-js": () => import("./../../../src/pages/torun-polityka-prywatnosci.js" /* webpackChunkName: "component---src-pages-torun-polityka-prywatnosci-js" */),
  "component---src-pages-torun-promocja-js": () => import("./../../../src/pages/torun-promocja.js" /* webpackChunkName: "component---src-pages-torun-promocja-js" */),
  "component---src-pages-torun-zabiegi-index-js": () => import("./../../../src/pages/torun-zabiegi/index.js" /* webpackChunkName: "component---src-pages-torun-zabiegi-index-js" */),
  "component---src-pages-torun-zabiegi-torun-kosmetologia-estetyczna-js": () => import("./../../../src/pages/torun-zabiegi/torun-kosmetologia-estetyczna.js" /* webpackChunkName: "component---src-pages-torun-zabiegi-torun-kosmetologia-estetyczna-js" */),
  "component---src-pages-torun-zabiegi-torun-laseroterapia-js": () => import("./../../../src/pages/torun-zabiegi/torun-laseroterapia.js" /* webpackChunkName: "component---src-pages-torun-zabiegi-torun-laseroterapia-js" */),
  "component---src-pages-torun-zabiegi-torun-modelowanie-sylwetki-js": () => import("./../../../src/pages/torun-zabiegi/torun-modelowanie-sylwetki.js" /* webpackChunkName: "component---src-pages-torun-zabiegi-torun-modelowanie-sylwetki-js" */),
  "component---src-pages-torun-zabiegi-torun-stylizacja-paznokci-js": () => import("./../../../src/pages/torun-zabiegi/torun-stylizacja-paznokci.js" /* webpackChunkName: "component---src-pages-torun-zabiegi-torun-stylizacja-paznokci-js" */)
}

